<template>
    <b-row>
        <b-col cols="12" md="8">
            <b-card title="Network Account Edit" v-if="networkData && selectedNetworkAccountData && !isLoading">
                <validation-observer ref="appForm" tag="form"
                                     @submit.prevent="validationFormControl().then(formSubmitted)">
                    <b-row class="align-items-center">
                        <!--                        {{ selectedNetworkAccountData }}-->
                        <b-col :md="12" v-if="networkFetchOptions">
                            <b-form-group label="Network" label-for="name">
                                <v-select id="network_id" v-model="selectedNetworkAccountData.network_id" disabled
                                          :options="networkFetchOptions" :reduce="(option) => option.value"
                                          label="label" placeholder="Select Network" class="w-100">
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="12" v-for="item in networkData.fields" :key="item.id"
                               v-if="item.type !== 'redirect'">
                            <validation-provider #default="{errors}" :name="item.name" rules="required">
                                <b-form-group :label="item.name" :label-for="item.name">
                                    <b-form-input :placeholder="item.name"
                                                  v-model="selectedNetworkAccountData.details[item.value_name]"
                                                  @input="handleValue($event, item.value_name)"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col :md="12" v-if="statusOptions">
                            <validation-provider #default="{errors}" name="Status" rules="required">
                                <b-form-group label="Status" label-for="name" :state="errors.length > 0 ? false:null">
                                    <v-select id="status" v-model="selectedNetworkAccountData.status"
                                              :options="statusOptions" :reduce="(option) => option.value"
                                              label="label" placeholder="Select Status" class="w-100">
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
                                Update
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </b-card>
            <b-card v-if="isLoading" class="mt-2">
                <div class="text-center my-2">
                    <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                    <strong>Content is being created, please wait...</strong>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12" md="4">
            <b-card class="card-transaction" no-body v-if="networkData && !isLoading && selectedNetworkAccountData">
                <b-card-header>
                    <b-card-title>Network Account Detail</b-card-title>
                </b-card-header>
                <b-card-body>
                    <div v-for="(item, index) in selectedNetworkAccountData.details" :key="item.id"
                         class="transaction-item">
                        <b-media no-body v-b-modal.result-detail class="cursor-default">
                            <b-media-body>
                                <small>{{ index }}</small>
                                <h6 class="transaction-title mb-0"  style=" line-break:anywhere;">
                                    {{ item }}
                                </h6>
                            </b-media-body>
                        </b-media>
                    </div>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@/components/AppCollapse.vue'
import AppCollapseItem from '@/components/AppCollapseItem.vue'
import router from '@/router'
import {required} from '@validations'
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BSpinner,
    BFormTextarea,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
} from 'bootstrap-vue'
import useNetworkAccountApi from "@/composables/useNetworkAccountApi";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCard,
        BCol,
        BFormGroup,
        BForm,
        BButton,
        BFormInput,
        BCardBody,
        BCardTitle,
        BCardHeader,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
        BFormCheckbox,
        AppCollapse,
        AppCollapseItem,
        BSpinner,
        BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            required,
            isLoading: false,
        }
    },
    methods: {
        formSubmitted() {
            this.isLoading = true
            this.$store.dispatch('networkAccountStore/updateNetworkAccount', this.selectedNetworkAccountData)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Successfully edited!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    router.push({name: 'network-account-list'})
                }).catch((errors) => {
                this.isLoading = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Please check form',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: errors.response ? errors.response.data.message : ''
                    },
                })
            })
        },
        validationFormControl() {
            return new Promise((resolve, reject) => {
                this.$refs.appForm.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        handleValue(event, data) {
            this.selectedNetworkAccountData.details[data] = event;
        }
    },
    setup() {
        const {
            fetchNetworks,
            fetchNetworkAccount,
            selectedNetworkAccountData,
            networkData,
            networkFetchOptions,
            statusOptions
        } = useNetworkAccountApi()
        return {
            fetchNetworks,
            fetchNetworkAccount,
            selectedNetworkAccountData,
            networkData,
            networkFetchOptions,
            statusOptions
        }
    },
    mounted() {
        this.fetchNetworkAccount(router.currentRoute.params.networkAccount)
        this.fetchNetworks()
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.ql-editor {
    min-height: 150px;
}

.ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #ebe9f1 !important;
}

.ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ebe9f1 !important;
}

.is-invalid .quill-editor {
    border: 1px solid red;
    border-radius: 5px;
}

.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>
